/* General Styles */
video {
  display: block;
  pointer-events: none;
}

.home-container {
  text-align: center;
  padding: 50px 20px; /* Adjust padding as needed */
}

.home-heading {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}

.home-text {
  font-size: 18px;
  line-height: 1.5;
  color: #333; /* Adjust text color as needed */
}

.centered-div {
  display: flex; /* Allow both text and image to fit side-by-side */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  margin: 20px auto; /* Add some margin for better spacing */
  padding: 15px; /* Add some padding for visual distinction */
  border: 1px solid #ddd; /* Optional border */
  border-radius: 20px; /* Optional rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); /* Add a right-side shadow */
}

.right-image {
  margin-left: 20px; /* Add spacing between text and image */
  width: 200px; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
}

.vid{
  height: 350px;
  width: auto;
}

@media screen and (max-width: 960px) {
  .vid{
    height: 180px;
  }
}