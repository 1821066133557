.contact-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .address {
    margin-bottom: 20px;
  }
  
  .contact-info {
    margin-bottom: 20px;
  }
  
  .contact-info p {
    margin-bottom: 10px;
  }
  
  a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  