.navbar {
  background-color: #F6F5F5;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;
  text-decoration: none;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
  text-decoration: none;
}

.nav-logo {
  color: #0C359E;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  flex-grow: 1;

  display: flex;
  align-items: center;
  justify-items: center;
  text-decoration: none;
}

.customIcon {
  width: 6rem; /* Adjust the width as needed */
  height: 6rem; /* Adjust the height as needed */
  display: inline-flex; /* Ensures the span behaves like an inline element */
  align-items: center; /* Aligns content vertically */
}

.customIcon img {
  max-width: 100%; /* Ensures the image does not exceed the container's width */
  max-height: 100%; /* Ensures the image does not exceed the container's height */
}

.nav-logo .icon {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  background-size: cover; 
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
}
.nav-links, .nav-links:hover {
  @apply no-underline; /* Leverage Tailwind utilities in your CSS */
}
.nav-links {
  text-decoration: none !important;
  color: #22036b;
  text-decoration: none; /* Remove default underline */
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #0C359E;
}

.nav-item .active {
  color: #0C359E;
  border: 1px solid #0C359E;
}

.nav-icon {
  display: none;
  color: #0C359E;
}

.nav-icon .icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 110%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 60px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #F6F5F5;
    left: -40px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    color: #3700ff;
    border: none;
  }
  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #0C359E;
  }
}
