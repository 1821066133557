.footer {
    background-color: #1a237e; /* Dark blue background color */
    color: #fff; /* White text color */
    text-align: center;
    padding: 20px 0; /* Adjust padding as needed */
    bottom: 0;
    width: 100%;
    z-index: 100; /* Ensure the footer stays on top of other content */
  }
  
  .footer-links {
    margin-bottom: 10px; /* Add space between links and text */
  }
  
  .footer-links a {
    color: #fff; /* White text color for links */
    text-decoration: none; /* Remove underline from links */
    margin: 0 10px; /* Adjust margin for spacing between links */
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Underline links on hover */
  }
  
  .footer-text {
    margin-top: 10px; /* Add space between links and text */
  }
  