.product-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .page-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .page-description {
    margin-bottom: 30px;
  }
  
  .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .product-item {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }
  
  .product-item h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .product-item p {
    font-size: 16px;
    color: #555;
  }
  